import React from 'react';
import { graphql} from 'gatsby';
import PublicApi from '../../templates/multilanguage/PublicApi';

export default ({ data }) => {
  return <PublicApi data={data} />;
};
export const query = graphql`
 query PublicApiRo {
  mdx(frontmatter: {type: {eq: "public-api"} language: {eq: "it"}}) {
      id
      body
      fields {
        slug
        canonical
        lang
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          fr {
            url
            country
          }
          es {
            url
            country
          }
          de {
            url
            country
          }
          pl {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        metaKeywords
        twitterCard
        ldJson
        image {
          publicURL
        }
        imageAlt
      }
  }
}
`
